import React, { useState, useEffect, useRef } from 'react';
// import Logo from '../../Images/logo black.png'
// import mobileLogo from '../../Images/mobile-logo.svg'
import logo from '../../assets/og logo.svg';
import { NavLink, Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import classes from '../HeaderNav/HeaderNav.module.css';
import { Navbar, Nav, Dropdown, NavDropdown } from 'react-bootstrap';


const HeaderNav3 = ({ scrollToSection, aboutUsRef, newsRef, onMenuClick }) => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  

 
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu open/close state
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Close the menu
  };

  // const headers = {
  //   'Content-Type': 'application/json',
  //   'Authorization': `Bearer ${bearer}`
  // };

  const handleGoBack = () => {
    navigate(-1);
  }


  return (
    <div className={classes.headerSection} >
      <nav expand="lg" className={classes.nav}>
        <div className={classes.navContainer} >
          <NavLink to={'/'} className={classes.navLogoWeb}><img src={logo}  className={classes.logoicon}/></NavLink>
          <ul className={`${classes.mainMenu} ${isMenuOpen ?  '' : classes.menuOpen}`} style={{marginTop: 10}}>
            <li className={classes.linkList}>
              {/* <Link to={'/ministry_urban_physical_planning'} >Home</Link>
              <Link to={'#'} onClick={() => onMenuClick('About Us')} >About us</Link>
              <Link to={'#'} onClick={() => onMenuClick('News')} >News</Link> */}
            </li>
            <li className={classes.navItem}>
            </li>
            <button onClick={handleGoBack} className={classes.mdaButton}>← Back</button>
          </ul>
          <div className={classes.harmborgers} onClick={toggleMenu}>
            {isMenuOpen ? (
              <span className={classes.closs}>
                <i className='bx bx-x'></i>

              </span>
            ) : (
              <span className={classes.open}>
                <i className='bx bx-menu'></i>
              </span>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderNav3;