import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, Link } from 'react-router-dom';
import UrbanHeader from "../../Components/urbanHeader/UrbanHeader";
import NewFooter from "../../Components/NewFooter";
import classes from "./mdas.module.css";
import NewFooter2 from "../../Components/NewFooter2";
import NewsIcon from '../../assets/olumo.png'
import HeaderNav2 from "../../Components/HeaderNav/HeaderNav2";
// import VideoFile from '../../video/landingpage.mp4';
import IMGFile from '../../assets/urban/logs.png'
import Comm1 from '../../assets/urban/oga.png'
import Pic1 from '../../assets/urban/pic1.png'
import Pic2 from '../../assets/urban/pic2.png'
import Pic3 from '../../assets/urban/pic3.png'
import Pic4 from '../../assets/urban/pic4.png'
import Pic5 from '../../assets/urban/pic5.png'
import Pic6 from '../../assets/urban/pic6.png'
import axios from "axios";
import { Spinner, Button, Accordion } from "react-bootstrap";
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import logo from '../../assets/og logo1.svg';
import Arrow from '../../assets/carousels/ban1.png';
import Arrow1 from '../../assets/carousels/ban2.png';
import Arrow2 from '../../assets/carousels/ban3.png';
import Service1 from '../../assets/carousels/service1.png';
import Service2 from '../../assets/carousels/service2.png';
import Service3 from '../../assets/carousels/service3.png';
import Down1 from '../../assets/carousels/down1.png';
import Down2 from '../../assets/carousels/down2.png';
import Down3 from '../../assets/carousels/down3.png';
import Down4 from '../../assets/carousels/ir.png';
import Icon1 from '../../assets/executives-icon/icon.png';
import Icon11 from '../../assets/landingpage/ic1.png'
import Icon2 from '../../assets/landingpage/ic2.png'
import Icon3 from '../../assets/landingpage/ic3.png'
import HalfMap from '../../assets/halfMap.svg';
import footerLogo from "../../assets/Footer-logo.svg";
import logo1 from '../../assets/New1.png';
import Mail from '../../assets/mail.png';
import Call from '../../assets/call.png';
import Location from '../../assets/location.png';


function OgunInvest() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);
    const [clickedMenu, setClickedMenu] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedMda, setSelectedMda] = useState([]);
    const [mdaLoading, setMdaLoading] = useState(false);

    const homeRef = useRef(null);
    const aboutUsRef = useRef(null);
    const newsRef = useRef(null);
    const opportunitiesRef = useRef(null);
    const processRef = useRef(null);
    const resourcesRef = useRef(null);
    const faqsRef = useRef(null);
    const contact = useRef(null);



    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };


    const handleNavigateToHome = () => {
        navigate('/')
    }

    const handleFetchMda = async () => {
        setMdaLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/get-mda-details`, {
            headers: {
              'Authorization': `Bearer ${TOKEN}`,
              'Content-Type': 'application/json'
            },
            params: {
              id: '86',
            }
          });
          const res = response.data?.data;
          setSelectedMda(res);
          console.log(res);
        } catch (error) {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setSelectedMda([]);
        } finally {
          setMdaLoading(false);
        }
      };
    
      useEffect(() => {
        if (TOKEN) {
            handleFetchMda();
        }
      }, [TOKEN]);

 
    let pictures = [];
    let videoss = [];
    let news = [];
    let faqs = [];
    let services = [];

if (selectedMda && selectedMda.media) {
    selectedMda.media.forEach(mediaItem => {
        if (mediaItem.media_type === "1") {
            pictures.push(mediaItem);
        } else if (mediaItem.media_type === "2") {
            videoss.push(mediaItem);
        }
    });

    if (selectedMda.news) {
      news = selectedMda.news;
  }

  if (selectedMda.faqs) {
      faqs = selectedMda.faqs; 
  }
  if (selectedMda.services) {
      services = selectedMda.services; 
  }
}


    function formatDate(dateString) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const date = new Date(dateString);
        const dayOfWeek = days[date.getDay()];
        const month = months[date.getMonth()];
        const dayOfMonth = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        return formattedDate;
    }

    const handleReadMore = (item) => {
        navigate('/read_news', { state: { selectedNews: item } });
    }

    const photos = [
        { url: Pic1, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic2, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic3, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic4, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic5, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' },
        { url: Pic6, description: 'Description of Photo here', subtext: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco' }
    ];

    const videos = [
        { url: "https://drive.google.com/file/d/1KlTQvHQnMyDnvf8AH3gI-beEONxffHB1/preview", description: 'Ogun Investment video' },
        { url: "https://drive.google.com/file/d/1KlTQvHQnMyDnvf8AH3gI-beEONxffHB1/preview", description: 'Ogun Investment video' },
        { url: "https://drive.google.com/file/d/1KlTQvHQnMyDnvf8AH3gI-beEONxffHB1/preview", description: 'Ogun Investment video' },
        { url: "https://drive.google.com/file/d/1KlTQvHQnMyDnvf8AH3gI-beEONxffHB1/preview", description: 'Ogun Investment video' },
    ];

    const handleGoBack = () => {
        navigate(-1);
    }

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    const handleReg = () => {
        navigate('/webinar_registration');
    }

    const getEmbedLink = (link) => {
        const fileId = link.match(/[-\w]{25,}/);
        return fileId ? `https://drive.google.com/file/d/${fileId[0]}/preview` : link;
      };
  
      const firstVideoUrl = videoss.length > 0 ? videoss[0].file_path : '';
  
      const maxLength = 1500;
      const truncatedText = selectedMda?.commisioners_profile?.length > maxLength ? selectedMda?.commisioners_profile?.substring(0, maxLength) + '...' : selectedMda?.commisioners_profile;

    return (
        <div>
            {/* <HeaderNav2 onMenuClick={handleMenuClick} /> */}
            <div className={classes.headerSection} >
                {/* <nav expand="lg" className={classes.nav}> */}
                <div className={classes.ognheadline}></div>
                <nav className={classes.nav}>
                    <div className={classes.ogninfo}>
                        <p>Mail us at: <span>investment@ogunstate.gov.ng</span></p>
                        <p>or call us at: <span>+234(0)9091661337, +234(0)8169213747</span></p>
                    </div>
                    <div className={classes.navContainer} >
                        <NavLink to={'/'} className={classes.navLogoWeb}><img src={logo} className={classes.logoicon} /></NavLink>
                        <ul className={`${classes.mainMenu} ${isMenuOpen ? '' : classes.menuOpen}`}>
                            <li className={classes.linkList}>
                                {/* <li to={'#'} >Home</li> */}
                                <li to={'#'} onClick={() => scrollToSection(aboutUsRef)}>About</li>
                                <li to={'#'} onClick={() => scrollToSection(opportunitiesRef)}>Opportunities </li>
                                <li to={'#'} onClick={() => scrollToSection(processRef)}>Process</li>
                                <li to={'#'} onClick={() => scrollToSection(newsRef)}>News</li>
                                <li to={'#'} onClick={() => scrollToSection(resourcesRef)}>Resources</li>
                                <li to={'#'} onClick={() => scrollToSection(faqsRef)}>FAQs</li>
                            </li>
                            <li className={classes.navItem}>
                            </li>
                            <button onClick={() => scrollToSection(contact)} className={classes.mdaButton}>Contact Us</button>
                        </ul>
                        <div className={classes.harmborgers} onClick={toggleMenu}>
                            {isMenuOpen ? (
                                <span className={classes.closs}>
                                    <i className='bx bx-x'></i>

                                </span>
                            ) : (
                                <span className={classes.open}>
                                    <i className='bx bx-menu'></i>
                                </span>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
            <div ref={homeRef} className={classes.generalBody}>
                <div className={classes.bodyContainer}>
                    <img src={Arrow} alt='banner' className={classes.arrowImg} />
                    {/* <p className={classes.firstText}>Ogun state get new influx of investors to boost its economy ➙</p> */}
                </div>
                <div className={classes.wrapper}>
                    <section className={classes.wrapperBackground}>

                        <h1>Invest in Ogun<br />State Bubbling Economy</h1>
                        <p className={classes.subHeaderText}>Invest in Ogun State's Bubbling Economy and unlock opportunities in Nigeria's emerging economic hub. Experience unmatched investment potential in a thriving environment where business meets prosperity, fostering sustainable growth and stability for entrepreneurs and investors alike.
                        </p>
                        <div className={classes.btnCont3}>
                            <Button className={classes.btnBtn}>View Investment Opportunities →</Button>
                            {/* <Button onClick={handleReg} className={classes.btnBtn}>Investment Webinar Registration →</Button> */}
                        </div>
                        <img src={Arrow1} alt='banner' className={classes.arrowImg2} />
                        <img src={Arrow2} alt='banner' className={classes.arrowImg3} />
                        <div className={classes.videoSection}>
                            <iframe
                                width="900"
                                height="600"
                                src={getEmbedLink(firstVideoUrl)}
                                title="Our videos"
                                frameBorder="1"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className={classes.videoIframe}
                                style={{ border: "3px solid #164B2E" }}
                            ></iframe>
                        </div>
                    </section>
                </div>



                <section ref={aboutUsRef} className={classes.headkad}>
                    <div className={classes.firstBox}>
                        <h1>OUR VISION</h1>
                        <p>{selectedMda.vision}</p>
                    </div>
                    <div className={classes.secondBox}>
                        <h1>OUR MISSION</h1>
                        <p>{selectedMda.mission}</p>
                    </div>
                </section>

                {/* OUR SERVICES */}

                {/* <div className={classes.aboutSection}> */}
                {/* <div className={classes.aboutText}> */}
                {/* <h1>OUR SERVICES</h1> */}
                {/* <p>MINISTRY OF URBAN AND PHYSICAL PLANNING</p> */}
                {/* </div> */}
                {/* </div> */}
                {/* <div className={classes.aboutHeader}>

                    <div style={{
                        backgroundImage: `url(${Service1})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        height: 400,
                        width: 300
                    }}>
                        <div className={classes.serviceBanner}>
                            <h1>Service1</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                        </div>
                    </div>
                    <div style={{
                        backgroundImage: `url(${Service2})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        height: 400,
                        width: 300
                    }}>
                        <div className={classes.serviceBanner}>
                            <h1>Service2</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                        </div>
                    </div>
                    <div style={{
                        backgroundImage: `url(${Service3})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        height: 400,
                        width: 300
                    }}>
                        <div className={classes.serviceBanner}>
                            <h1>Service3</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</p>
                        </div>
                    </div>
                </div> */}

                {/* <div className={classes.bioSection}>
                    <div className={classes.bioText}>
                        <h1>BOARD OF OGUN INVEST</h1>
                    </div>
                    <div className={classes.bioContainer}>
                        <div className={classes.bioItem}>
                            <img src={Icon1} alt="icon" style={{ objectFit: 'contain', height: 300, width: 200 }} />
                            <h2>NAME OF BOARD MEMBER</h2>
                            <p>BOARD MEMBER 1</p>
                        </div>
                        <div  className={`${classes.bioItem} ${classes.furmble}`}>
                            <img src={Icon1} alt="icon" style={{ objectFit: 'contain', height: 400, width: 300 }} />
                            <h2>NAME OF BOARD MEMBER 2</h2>
                            <p>BOARD MEMBER 2</p>
                        </div>
                        <div className={`${classes.bioItem} ${classes.furmble}`}>
                            <img src={Icon1} alt="icon" style={{ objectFit: 'contain', height: 300, width: 200 }} />
                            <h2>NAME OF BOARD MEMBER 3</h2>
                            <p>BOARD MEMBER 3</p>
                        </div>
                    </div>
                </div> */}


                <section ref={opportunitiesRef} className={`${classes.ogunInvestment} ${classes.ogninvstmble}`}>

                </section>

                <p className={classes.xplore12}>OGUN STATE</p>
                <p className={classes.xploretxt12}>Ogun State Key Sectors</p>

                <div className={`${classes.ogunInvestment1} ${classes.ogninvstmble2}`} >

                </div>
                <div className={`${classes.ogunInvestment3} ${classes.ogninvstmble3}`}>

                </div>
                <div className={`${classes.ogunInvestment4} ${classes.ogninvstmble4}`}>

                </div>


                <p ref={processRef} className={classes.xplore}>INVESTMENT PROCESS</p>
                <p className={classes.xploretxt}>Do business with Ogun State at the comfort of your homes and offices with peace of mind</p>


                <div className={`${classes.wrapper} ${classes.threeCardsbg}`}>
                    <div className={`${classes.container} ${classes.threeCards}`}>
                        <div className={`${classes.cards} ${classes.card4}`}>
                            <div className={classes.cardItems}>
                                <img src={Icon11} className={classes.eclipse} />
                                <h2 className={classes.cardText}>Process
                                    with Bureau
                                    of Land and
                                    Survey</h2>

                            </div>
                        </div>

                        <div className={`${classes.cards} ${classes.card4}`}>
                            <div className={classes.cardItems}>
                                <img src={Icon2} className={classes.eclipse} />
                                <h2 className={classes.cardText}>Process
                                    with the
                                    Ministry of
                                    Agriculture</h2>
                            </div>
                        </div>
                        <div className={`${classes.cards} ${classes.card4}`}>
                            <div className={classes.cardItems}>
                                <img src={Icon3} className={classes.eclipse} />
                                <h2 className={classes.cardText}>Process
                                    with the Ministry
                                    of Physical
                                    Planning and
                                    Urban
                                    development</h2>
                            </div>
                        </div>
                        <div className={`${classes.cards} ${classes.card4}`}>
                            <div className={classes.cardItems}>
                                <img src={Icon3} className={classes.eclipse} />
                                <h2 className={classes.cardText}>Process
                                    with the
                                    Ministry of
                                    Environment</h2>
                            </div>
                        </div>
                        <div className={`${classes.cards} ${classes.card4}`}>
                            <div className={classes.cardItems}>
                                <img src={Icon3} className={classes.eclipse} />
                                <h2 className={classes.cardText}>Incentives</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <section ref={newsRef} className={classes.newsSection}>
                    <h3>NEWS</h3>
                    <div className={classes.newsGroup}>
                        <div className={classes.newsContainer}>
                            <img
                                className="d-block w-100"
                                src={NewsIcon}
                                alt="News"
                                style={{ objectFit: 'cover', height: 420, borderRadius: 8, padding: 10 }}
                            />
                            <p>Catch up with news and events happening in and around Ogun State. </p>
                        </div>

                        <div className={classes.newsData1}>
  {mdaLoading ? (
    <Spinner />  
  ) : (
    <>
      {news.length === 0 ? (
        <p className={classes.recent}>No recent news to display</p>
      ) : (
        news?.slice(0, 6).map((item, index) => (
          <div key={index} className={classes.newsData2}>
            <h1 onClick={() => handleReadMore(item)}>{item?.title}</h1>
            <p>{item?.short_desc}</p>
            <div className={classes.newsBelow}>
              <p>Posted on {formatDate(item.created_date)}</p>
              <h1 onClick={() => handleReadMore(item)}>Read more...</h1>
            </div>
          </div>
        ))
      )}
    </>
  )}
</div>

                    </div>


                    {news.length > 6 && (
        <Button
          onClick={() => navigate('/news', { state: { documents: news } })}
          variant="success"
        >
          Read more
        </Button>
      )}
                </section>


                <div className={classes.bioSection1}>
                    <div className={classes.photoText}>
                        <h1>Photo Gallery</h1>
                    </div>
                    <div className={classes.videoContainer1}>
                    { pictures.length === 0 ? (
                      <div className={classes.noVid}>
                <p className={classes.recent12}>No picture to display</p>
                </div>
              ) : (
                pictures.map((photo, index) => (
                    <div key={index} className={classes.photoItem}>
                        <img src={photo.file_path} alt={photo.title} />
                        <div className={classes.photoDescription}>{photo.title}</div>
                        <div className={classes.photoSubtext}>{photo.description}</div>
                    </div>
                  ))
                      )}
                    </div>
                    {/* {pictures.length === 0 ? null : (
                    <div className={classes.videoBottom}>
                        <h1>View more</h1>
                    </div>
                    )} */}

                </div>
                <div className={classes.bioSection12}>
                    <div className={classes.videoText}>
                        <h1>Watch our Programs and Conferences in Video</h1>
                    </div>
                    <div className={classes.videoContainer}>
                    { videoss.length === 0 ? (
                      <div className={classes.noVid}>
                <p className={classes.recent12}>No video to display</p>
                </div>
              ) : (
                        videoss.map((video, index) => (
                            <div key={index} className={classes.videoItem}>
                                <iframe
                                    src={getEmbedLink(video.file_path)}
                                    title={`Video ${index + 1}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                                <div className={classes.videoDescription}>{video.title}</div>
                            </div>
                        ))
                      )}
                    </div>
                    {/* {videoss.length === 0 ? null : (
                    <div className={classes.videoBottom}>
                        <h1>View more</h1>
                    </div>
                    )} */}

                </div>

                {/* <p ref={resourcesRef} className={classes.xplore}>DOWNLOADABLE RESOURCES</p> */}
                {/* <div className={classes.contCont}>
                    <div className={classes.aboutHeader1}>
                        <div className={classes.imageText}>
                            <img
                                src={Down1}
                                alt="download"
                                style={{
                                    objectFit: 'contain', height: 400,
                                    width: 300
                                }}
                            />
                            <div className={classes.bookCont}>
                                <img
                                    src={Down4}
                                    alt="download"
                                    style={{
                                        objectFit: 'contain', height: 60,
                                        width: 60
                                    }}
                                />
                                <div className={classes.bookCont1}>
                                    <h1>Book of State</h1>
                                    <p>Download here</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.imageText}>
                            <img
                                src={Down2}
                                alt="download"
                                style={{
                                    objectFit: 'contain', height: 400,
                                    width: 300
                                }}
                            />
                            <div className={classes.bookCont}>
                                <img
                                    src={Down4}
                                    alt="download"
                                    style={{
                                        objectFit: 'contain', height: 60,
                                        width: 60
                                    }}
                                />
                                <div className={classes.bookCont1}>
                                    <h1>Invest in Ogun State <br />
                                        Sectorial Investment<br />
                                        Opportunities Booklet Guide</h1>
                                    <p>Download here</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.imageText}>
                            <img
                                src={Down3}
                                alt="download"
                                style={{
                                    objectFit: 'contain', height: 400,
                                    width: 300
                                }}
                            />
                            <div className={classes.bookCont}>
                                <img
                                    src={Down4}
                                    alt="download"
                                    style={{
                                        objectFit: 'contain', height: 60,
                                        width: 60
                                    }}
                                />
                                <div className={classes.bookCont1}>
                                    <h1>Ogun State <br />
                                        Investment Opportunities</h1>
                                    <p>Scan Qr code</p>
                                </div>
                            </div>

                        </div>


                    </div>
                </div> */}

                <div ref={contact} className={classes.bioSections}>
                    <div className={classes.contactContainer}>
                        <div className={classes.contact1}>
                            <h1>Contact Us</h1>
                            <p>Want to reach us? Contact us through <br /> the following channels</p>
                        </div>
                        <div className={classes.contact123}>
                            <img
                                className="d-block w-100"
                                src={logo1}
                                alt="og"
                                style={{ objectFit: 'contain', height: 200, }}
                            />
                            {/* <p>Want to reach us? Contact us through <br /> the following channels</p> */}
                        </div>
                        <div className={classes.contact12}>
                            <img
                                className="d-block w-100"
                                src={Mail}
                                alt="og"
                                style={{ objectFit: 'contain', height: 30, }}
                            />
                            <p>investment@ogunstate.gov.ng
                            </p>
                            <img
                                className="d-block w-100"
                                src={Call}
                                alt="og"
                                style={{ objectFit: 'contain', height: 30, }}
                            />
                            <p>{selectedMda.phone} {selectedMda.phone_2}</p>
                            <img
                                className="d-block w-100"
                                src={Location}
                                alt="og"
                                style={{ objectFit: 'contain', height: 30, }}
                            />
                            <p>4th Floor, OPIC House,
                                Lagos-Ibadan Expressway, Isheri, Ogun State.
                            </p>
                            <img
                                className="d-block w-100"
                                src={Location}
                                alt="og"
                                style={{ objectFit: 'contain', height: 30, }}
                            />
                            <p>Block D,Room 115, State Secretariat Oke-Mosan, Abeokuta, Ogun State.</p>
                        </div>
                    </div>
                </div>

                <p ref={faqsRef} className={classes.xplore}>FAQs</p>
                <p className={classes.xploretxt}>See answers to some frequently asked questions here</p>
                <div className={classes.accCont}>
                    <div className={classes.accordionContainer}>
                    <Accordion>
    {faqs.length === 0 ? (
        <p className={classes.recent}>No faqs to display</p>
    ) : (
        faqs.map((faq, index) => (
            <Accordion.Item className={classes.accordionSpace} eventKey={index.toString()} key={index}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>{faq.answer}</Accordion.Body>
            </Accordion.Item>
        ))
    )}
</Accordion>
                    </div>
                </div>




















            </div>
            <div className={`${classes.wrapper} ${classes.FooterBg2}`}>
                <div className={classes.containers}>
                    <img src={HalfMap} alt="Ogun Map" className={classes.halfmap} />
                    <div className={`${classes.container} ${classes.mainFooter}`}>
                        {/* <div className={classes.finalDiv}> */}
                        <div onClick={handleNavigateToHome} className={classes.footerLogoCont}>
                            <div className={classes.footerLogo}>
                                <img src={footerLogo} alt="Ogun Logo" />
                            </div>
                            <p style={{ color: 'white' }} >
                                It is peopled predominantly by the Egbas, Ijebus, Yewas, Remos and Aworis; - who belong to the main Yoruba ethnic group.  It also has sub-groups, namely, Ketu, Ikale, Ilaje, Ohori, Anago and Egun. There are also significant numbers of Nigerians from other parts of the country as well as foreign nationals resident in the State.

                                Ogun State welcomes you!
                            </p>
                        </div>

                        <div className={`${classes.footerConts} ${classes.footerContsnav}`}>
                            <h5 className={classes.name}>Link</h5>
                            <div className={classes.footList}>
                                <li to={'#'} onClick={() => scrollToSection(homeRef)}>Home</li>
                                <li to={'#'} onClick={() => scrollToSection(aboutUsRef)}>About Us</li>
                                <li to={'#'} onClick={() => scrollToSection(opportunitiesRef)}>Opportunities </li>
                                <li to={'#'} onClick={() => scrollToSection(processRef)}>Process</li>
                                <li to={'#'} onClick={() => scrollToSection(newsRef)}>News</li>
                                <li to={'#'} onClick={() => scrollToSection(resourcesRef)}>Resources</li>
                                <li to={'#'} onClick={() => scrollToSection(faqsRef)}>FAQs</li>
                                {/* <NavLink to={'/'} ><p>Home</p></NavLink> */}
                                {/* <NavLink to={'/'} ><p>Event</p></NavLink>
              <NavLink to={'/'} ><p>Blog</p></NavLink>
              <NavLink to={'/'} ><p>News</p></NavLink> */}
                                {/* <NavLink to={'/ogstep_documents'} ><p>Contracts & Adverts</p></NavLink> */}
                            </div>
                        </div>

                        {/* <div className={classes.footerConts}>
              <h5 className={classes.name}>MDAs</h5>
              <div>
                <p>Bureau of Public service reforms</p>
                <p>Ministry of Education, Science and Technology</p>
                <p>Ministry of Budget and Planning</p>
                <p>Ministry of Political Affairs and Administration</p>
              </div>
            </div> */}
                        <div className={classes.footerConts}>
                            <h5 className={classes.name}>Contact Us</h5>
                            <div>
                                <p>4th Floor, OPIC House,
                                    Lagos-Ibadan Expressway, Isheri, Ogun State.</p>
                            </div>
                            <div style={{ marginTop: -10 }}>
                                <p>Block D,Room 115, State Secretariat Oke-Mosan, Abeokuta, Ogun State.</p>
                            </div>
                            <p style={{ marginTop: -15 }}><span style={{ color: "green", fontWeight: 700, }}>E-mail: </span>investment@ogunstate.gov.ng</p>
                            <div>

                                <p style={{ marginTop: -15 }}><span style={{ color: "green", fontWeight: 700 }}>Phone:</span>+234(0)9091661337 , +234(0)8169213747</p>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <div className={classes.footerText}>
                <p className={classes.subFooter}>© OGUN STATE GOVERNMENT </p>
                <p className={classes.subFooter1}>All Rights Reserved. Terms of Use and Privacy Policy</p>
            </div>
        </div >
    )
}
export default OgunInvest;